import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import localStorage from '../../services/cart';
import { SpaProvider as DataProvider } from './checkout.provider';
import SignatureInstance from 'signature_pad';
import {
    Counter,
    Loading,
    DatePickerComponent,
    Disclaimer,
    TextArea,
    Header,
} from '../../components/components';

import dateConfig from './dateConfig';
import timeConfig from './timeConfig';
import styles from './spaCheckout.module.css';
import { convertTimeUTC } from './timeUTCConvertor';
import confirmationStyles from './food.module.css';
import { getVerifyRoomNo } from '../../services/api';
import ReactGA from 'react-ga4';
import { dataURItoBlob, printCurrencySymbol } from '../../helpers/commonHelper';
import { useLanguage } from '../../context/LanguageContext';
import { renderPrice } from '../../helper/commonHelper';
import { useHotel } from '../../context/HotelContext';
import Signature from '../../components/Signature';
import { Checkbox, Button } from 'antd';

const Checkout = ({ placeOrder, roomNumber }) => {
    const [roomNo, setRoomNo] = useState(roomNumber);
    const [guests, updateGuests] = useState(1);
    const [PUDate, setPUDate] = useState('');
    const [PUHour, setPUHour] = useState('');
    const [spaStartTime, setSpaStartTime] = useState(8);
    const [spaEndTime, setSpaEndTime] = useState(18);
    const [allTaxes, setAllTaxes] = useState(null);
    const [totalWithTax, settotalWithTax] = useState(0);
    const [instructions, updateInstructions] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    const [signaturePad, setSignaturePad] = useState(null);
    const [isDisclaimer, setisDisclaimer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [isRoomNoInUrl, setisRoomNoInUrl] = useState(true);
    const { hotelDetails } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);
    const navigate = useNavigate();
    const location = useLocation();
    const requestValid = guests && PUDate && PUHour;
    const selectedItem = location.state.selectedItem || {};
    const { t, lang } = useLanguage();

    const canvasfunc = () => {
        const canvas = document.getElementById('signature-canvas');
        if (canvas) {
            const SignaturePad = new SignatureInstance(canvas);
            setSignaturePad(SignaturePad);
        }
    };
    const getTimeMinHour = () => {
        let startTimeHour = 8;
        let endTimeHour = 18;
        // if (hotelDetails.id == 27) {
        //   const todayDate = new Date().toLocaleDateString();
        //   const userseletedDate = new Date(PUDate).toLocaleDateString();
        //   if (todayDate == userseletedDate) {
        //     startTimeHour = new Date().getHours() + 2;
        //   }
        // } else
        if (selectedItem?.spaTime?.open_time) {
            startTimeHour = selectedItem?.spaTime?.open_time?.split(':')[0];
        }
        if (selectedItem?.spaTime?.close_time) {
            endTimeHour = selectedItem?.spaTime?.close_time?.split(':')[0];
        }
        setSpaStartTime(startTimeHour);
        setSpaEndTime(endTimeHour);
    };
    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    const getTnC = () => {
        const dataTnc = localStorage.getItem('laundrySpa');
        if (dataTnc && dataTnc != 'null') {
            return dataTnc;
        }
        return '';
    };
    const getItemTax = () => {
        let itemTax = 0;
        let sumPrice = selectedItem?.price;
        let taxes = [];
        // adding item price, taxes
        if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
            if (selectedItem.taxes?.length) {
                selectedItem.taxes.sort(
                    (a, b) => a?.tax?.priority - b?.tax?.priority
                );
            }
        }

        if (selectedItem?.taxes?.length) {
            if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
                let prevPriceWithTax = sumPrice;
                selectedItem.taxes?.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            prevPriceWithTax *
                            guests *
                            (tax?.tax?.percentage / 100);
                        const taxdata = {
                            name: tax?.tax?.name,
                            taxValue: taxValue,
                        };
                        taxes.push(taxdata);
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                        itemTax = itemTax + taxValue;
                    } else if (taxType === 'absolute') {
                        const taxValue = guests * tax?.tax?.percentage;
                        const taxdata = {
                            name: tax?.tax?.name,
                            taxValue: taxValue,
                        };
                        taxes.push(taxdata);
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                        itemTax = itemTax + taxValue;
                    }
                });
            } else {
                selectedItem.taxes.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            sumPrice * guests * (tax?.tax?.percentage / 100);
                        const taxdata = {
                            name: tax?.tax?.name,
                            taxValue: taxValue,
                        };
                        taxes.push(taxdata);
                        itemTax = itemTax + taxValue;
                    } else if (taxType === 'absolute') {
                        const taxValue = guests * tax?.tax?.percentage;
                        const taxdata = {
                            name: tax?.tax?.name,
                            taxValue: taxValue,
                        };
                        taxes.push(taxdata);
                        itemTax = itemTax + taxValue;
                    }
                });
            }
        }
        setAllTaxes(taxes);
        return itemTax;
    };

    const getTotal = () => {
        const totalAmount = (getItemTax() + selectedItem.price) * guests;
        settotalWithTax(totalAmount);
    };

    useEffect(() => {
        console.log(selectedItem);
        canvasfunc();
    }, []);
    useEffect(() => {
        getTimeMinHour();
    }, [PUDate, PUHour]);

    useEffect(() => {
        getTotal();
    }, [selectedItem, guests]);

    useEffect(() => {
        const v = localStorage.getItem('isRoomNoInUrl');
        setisRoomNoInUrl(v === '1');
    }, []);

    if (!Object.keys(selectedItem).length) {
        navigate('/');
        return <div />;
    }

    const changeDateFormat = async (date) => {
        var selecteddate = new Date(date);
        let day = '' + selecteddate.getDate();
        let month = '' + (selecteddate.getMonth() + 1);
        let year = selecteddate.getFullYear();
        if (month.length < 2) month = 0 + month;
        if (day.length < 2) day = 0 + day;
        console.log(year + '/' + month + '/' + day);
        return year + '/' + month + '/' + day;
    };

    const placeOrderHandler = async () => {
        if (
            guests < 1 ||
            !PUDate ||
            PUDate.trim() === '' ||
            !PUHour ||
            PUHour.trim() === ''
        ) {
            setError(t['checkout_laundry_error']);
            window.scrollTo(0, 0);
            return false;
        }
        if (!roomNo) {
            setError(t['checkout_spa_error']);
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        if (signaturePad.isEmpty()) {
            setError(t['common_signature_empty_error']);
            canvasfunc();
            setLoading(false);
            window.scrollTo(0, 0);
            return;
        }
        await localStorage.setItem('room_no', roomNo);
        const getFormatedDate = await changeDateFormat(PUDate);
        var selectedtime = `${getFormatedDate} ${PUHour}`;
        var picupdate;
        if (selectedtime) {
            picupdate = convertTimeUTC(selectedtime);
        }
        console.log(`utc final time is ${picupdate}`);
        setLoading(true);
        setError('');

        const dataURL = signaturePad.toDataURL();
        let blob = dataURItoBlob(dataURL);
        let signatureImage = new File([blob], 'signature.png', {
            type: 'image/jpeg',
        });
        try {
            await placeOrder({
                schedule: picupdate,
                item: { spa_item_id: selectedItem.id, quantity: 1 },
                guestNumber: guests,
                instructions,
            });
            ReactGA.set({ spaOrderPlaced: true });
            ReactGA.event({
                category: 'Spa',
                action: 'Spa Order Placed',
            });
            return navigate('/spa-thank-you');
        } catch (e) {
            setError(t['checkout_spa_error']);
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    const decrementGuests = () => {
        if (guests >= 1) {
            updateGuests(guests - 1);
            return;
        }
    };

    const onRoonNoBlur = () => {
        setLoading(true);
        getVerifyRoomNo(roomNo)
            .then((res) => {
                const r = res?.data;
                setError('');
            })
            .catch((err) => {
                const e = err?.response?.status;
                if (e === 404) {
                    setRoomNo(null);
                    window.scrollTo(0, 0);
                    setError(t['valid_room_no_msg']);
                }
            })
            .finally(() => setLoading(false));
    };
    return (
        <>
            {loading && <Loading />}
            <Header showBack />

            <div className='flex flex-col gap-12 p-2 pb-16'>
                <div className='flex flex-col gap-2'>
                    {hotelDetails?.hotel_term?.map((item) => {
                        if (item?.type === 'ird_cart_top') {
                            return (
                                <p
                                    className={'ird-checkout-warning '}
                                    dangerouslySetInnerHTML={{
                                        __html: `&#x26A0; ${item.term}`,
                                    }}
                                ></p>
                            );
                        }
                    })}
                    {error && (
                        <div className={styles.errorMessage}>
                            {t['checkout_laundry_error']}
                        </div>
                    )}

                    <h3 className={styles.spaCheckoutTitle}>
                        {t['checkout_spa_package_selected']}
                    </h3>

                    <div className={styles.price__item}>
                        <span className={styles.price__label}>
                            {selectedItem.name}
                        </span>
                        <span className={styles.tax__val}>
                            {currentCurrency}{' '}
                            {renderPrice(
                                selectedItem.price * guests,
                                hotelDetails
                            )}
                        </span>
                    </div>
                    <div className={styles.total}>
                        {allTaxes?.map((item) => {
                            return (
                                <div className={styles.price}>
                                    <div className={styles.price__item}>
                                        <span className={styles.price__label}>
                                            {item.name}
                                        </span>
                                        <span className={styles.tax__val}>
                                            {' '}
                                            {currentCurrency}{' '}
                                            {renderPrice(
                                                item.taxValue,
                                                hotelDetails
                                            )}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                        <div
                            className={styles.price__item}
                            style={{
                                borderTop: '1px solid #e8e8e8',
                                paddingTop: '1rem',
                            }}
                        >
                            <span className={styles.grand__pay__label}>
                                {t['common_grand_total']}
                            </span>
                            <span className={styles.grand__pay__val}>
                                {currentCurrency}{' '}
                                {renderPrice(totalWithTax, hotelDetails)}
                            </span>
                        </div>
                    </div>
                </div>

                <div className='flex flex-col gap-2'>
                    <div className={'flex  justify-between items-center'}>
                        <label className='flex-1'>{t['room_villa_no']}</label>
                        {!isRoomNoInUrl ? (
                            <input
                                type='text'
                                value={roomNo}
                                onChange={(e) => {
                                    setRoomNo(e?.target?.value);
                                }}
                                onBlur={() => onRoonNoBlur()}
                                placeholder='Room Number'
                                className='roomNumberInput flex-1'
                            />
                        ) : (
                            <p>{roomNumber}</p>
                        )}
                    </div>
                    <div className={styles.guestsCounterSpa}>
                        <label>{t['checkout_number_of_guests']}</label>
                        <Counter
                            count={guests}
                            increment={() => updateGuests(guests + 1)}
                            decrement={() => decrementGuests()}
                        />
                    </div>
                    <div className='preOrder-wrapper flex gap-3 flex-col on'>
                        <div className={styles.timeInputWrapper}>
                            <p className={styles.selectTime}>
                                {t['common_select_date']}
                            </p>
                            <DatePickerComponent
                                placeholder={t['select_date']}
                                value={PUDate}
                                config={dateConfig}
                                confirmText={t['common_done_btn']}
                                cancelText={t['common_cancel']}
                                saveTime={(time) => {
                                    setPUHour(null);
                                    setPUDate(time);
                                }}
                                cType='date'
                            />
                        </div>
                        <div className={styles.timeInputWrapper}>
                            <p className={styles.selectTime}>
                                {t['common_select_time']}
                            </p>
                            <DatePickerComponent
                                placeholder={t['select_time']}
                                value={PUHour}
                                config={timeConfig}
                                confirmText={t['common_done_btn']}
                                cancelText={t['common_cancel']}
                                errorText={`${t['checkout_common_time_error_msg']} ${spaStartTime}:00 and ${spaEndTime}:00`}
                                saveTime={(time) => setPUHour(time)}
                                cType='hour'
                                minHour={spaStartTime}
                                maxHour={
                                    selectedItem?.spaTime?.close_time
                                        ? selectedItem?.spaTime?.close_time?.split(
                                              ':'
                                          )[0]
                                        : 18
                                }
                            />
                        </div>
                    </div>
                    <div className='flex  justify-center items-start flex-col gap-2'>
                        <label>{t['checkout_instructions']}</label>
                        <TextArea
                            value={instructions}
                            onChange={(e) => updateInstructions(e.target.value)}
                        />
                    </div>
                    <Signature signaturePad={signaturePad} />
                    {getTnC() && (
                        <RenderConfirmationCheckbox
                            hotelDetails={hotelDetails}
                            setIsChecked={setIsChecked}
                            isChecked={isChecked}
                            label={`${t['common_agree_statement']} ${t['spa_terms_and_conditions']}`}
                        />
                    )}
                    <div className={styles.proceedSpaWrapper}>
                        <Button
                            disabled={
                                PUDate === '' ||
                                PUHour === '' ||
                                roomNo === '' ||
                                !isChecked
                            }
                            type='primary'
                            onClick={() => placeOrderHandler()}
                            size='large'
                            className='bg-theme'

                        >
                            {t['common_proceed_btn']}
                        </Button>
                    </div>
                </div>
            </div>
            {isDisclaimer && (
                <Disclaimer
                    onClose={() => setisDisclaimer(false)}
                    disclaimerText={getTnC()}
                />
            )}
        </>
    );
};

export default DataProvider(Checkout);

const RenderConfirmationCheckbox = (props) => {
    const { setIsChecked, isChecked, hotelDetails, label } = props;
    // let checkboxLabelNode = hotelDetails?.hotel_term.find(
    //     (item) => item.type === 'spa_terms_and_conditions'
    // );
    // const checkboxLabel = checkboxLabelNode['term'] || 'test';
    return (
        <Checkbox
            onChange={(e) => setIsChecked(e.target.checked)}
            checked={isChecked}
            id='term-check'
        >
            {label}
        </Checkbox>
    );
};
