import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate, useLocation } from 'react-router-dom';
import localStorage from '../../services/cart';
import { LaundryProvider as DataProvider } from './checkout.provider';
// import toBuffer from "image-data-uri";
import {
    CheckoutCard,
    Loading,
    DatePickerComponent,
    Disclaimer,
    TextArea,
    Header,
} from '../../components/components';
import { useLanguage } from '../../context/LanguageContext';

import dateConfig from './dateConfig';
import timeConfig from './timeConfig';
import styles from './laundryCheckout.module.css';
import { convertTimeUTC } from './timeUTCConvertor';
import confirmationStyles from './food.module.css';
import { getVerifyRoomNo } from '../../services/api';
import ReactGA from 'react-ga4';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { renderPrice } from '../../helper/commonHelper';
import { useHotel } from '../../context/HotelContext';
import { Button, Switch, Checkbox } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

let minHour = 8,
    maxHour = 17;

const Checkout = ({ placeOrder, roomNumber }) => {
    const location = useLocation();
    const flattenedItems = location.state.flattenedItems;
    const [roomNo, setRoomNo] = useState(roomNumber);
    const [immediatePU, toggleImmediatePU] = useState(false);
    const [PUDate, setPUDate] = useState('');
    const [instructions, updateInstructions] = useState('');
    const [PUHour, setPUHour] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [render, forceRender] = useState(Math.random());
    const [isRoomNoInUrl, setisRoomNoInUrl] = useState(true);
    const [isChecked, setIsChecked] = useState(true);
    const { hotelDetails } = useHotel();
    const navigate = useNavigate();
    const [selectedItems, selectItems] = useState(flattenedItems);
    const [roomverificationLoader, setRoomverificationLoader] = useState(false);
    const currentCurrency = printCurrencySymbol(hotelDetails);

    const [isDisclaimer, setisDisclaimer] = useState(false);
    const [deliveryType, setDeliveryType] = useState(null);
    const { t, lang } = useLanguage();

    useEffect(() => {
        if (hotelDetails.id == 217) {
            minHour = 1;
            maxHour = 24;
        }
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    const getTnC = () => {
        const dataTnc = localStorage.getItem('laundryDis');
        if (dataTnc != 'null') {
            return dataTnc;
        }
        return '';
    };

    useEffect(() => {
        if (!selectedItems?.length) {
            navigate('/');
        }
    }, [selectedItems]);

    useEffect(() => {
        const v = localStorage.getItem('isRoomNoInUrl');

        setisRoomNoInUrl(v === '1');
    }, []);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (!flattenedItems.length) {
        navigate('/');
        return <div />;
    }

    const getPrice = ({ price, quantity, taxes = [] }) => {
        let sumPrice = price * quantity;
        let itemPrice = sumPrice;
        let itemTax = 0;
        if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
            if (taxes?.length) {
                taxes.sort((a, b) => a?.tax?.priority - b?.tax?.priority);
            }
        }

        if (taxes?.length) {
            if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
                let prevPriceWithTax = sumPrice;
                taxes?.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            prevPriceWithTax * (tax?.tax?.percentage / 100);
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                        itemTax = itemTax + taxValue;
                    } else if (taxType === 'absolute') {
                        const taxValue = tax?.tax?.percentage;
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                        itemTax = itemTax + taxValue;
                    }
                });
            } else {
                taxes.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            sumPrice * (tax?.tax?.percentage / 100);
                        itemTax = itemTax + taxValue;
                    } else if (taxType === 'absolute') {
                        const taxValue = tax?.tax?.percentage;
                        itemTax = itemTax + taxValue;
                    }
                });
            }
        }
        // if (taxes.length) {
        //   const sumTaxes = taxes.reduce(
        //     (a, b) => a.tax.percentage + b.tax.percentage
        //   );
        //   const itemTax =
        //     sumPrice *
        //     (taxes.length > 1 ? sumTaxes / 100 : taxes[0].tax.percentage / 100);
        //   sumPrice = sumPrice + itemTax;
        // }
        return itemPrice + itemTax;
    };

    const getTax = ({ price, quantity, taxes = [] }) => {
        let sumPrice = price * quantity;
        let t = [];
        // adding item price, taxes
        if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
            if (taxes?.length) {
                taxes.sort((a, b) => a?.tax?.priority - b?.tax?.priority);
            }
        }

        if (taxes?.length) {
            if (hotelDetails?.hotel_config?.taxation_type === 'priority') {
                let prevPriceWithTax = sumPrice;
                taxes?.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            prevPriceWithTax * (tax?.tax?.percentage / 100);
                        const taxdata = {
                            name: tax?.tax?.name,
                            tax: taxValue,
                        };
                        t.push(taxdata);
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                    } else if (taxType === 'absolute') {
                        const taxValue = tax?.tax?.percentage;
                        const taxdata = {
                            name: tax?.tax?.name,
                            tax: taxValue,
                        };
                        t.push(taxdata);
                        prevPriceWithTax = prevPriceWithTax + taxValue;
                    }
                });
            } else {
                taxes.forEach((tax) => {
                    let taxType = tax.tax.type || 'percentage';
                    if (taxType === 'percentage') {
                        const taxValue =
                            sumPrice * (tax?.tax?.percentage / 100);
                        const taxdata = {
                            name: tax?.tax?.name,
                            tax: taxValue,
                        };
                        t.push(taxdata);
                    } else if (taxType === 'absolute') {
                        const taxValue = tax?.tax?.percentage;
                        const taxdata = {
                            name: tax?.tax?.name,
                            tax: taxValue,
                        };
                        t.push(taxdata);
                    }
                });
            }
        }
        return t;
    };

    const getTaxes = () => {
        if (!flattenedItems.length) {
            return [];
        }
        const taxes = [];
        const allWithTaxes = flattenedItems?.map((item) => getTax(item));
        allWithTaxes.forEach((t1) => {
            // eslint-disable-next-line no-unused-expressions
            t1?.forEach((t) => {
                let i =
                    taxes?.findIndex((x) => x.name === t.name) < 0
                        ? taxes.length
                        : taxes?.findIndex((x) => x.name === t.name);
                taxes[i] = { tax: t.tax + (taxes[i]?.tax || 0), name: t?.name };
            });
        });
        return taxes;
    };
    const getDeliveryCharges = (totalAmount) => {
        let chargeValue;
        hotelDetails.hotel_delivery_types.forEach((item) => {
            if (item.id === deliveryType) {
                if (item.charge_type === 'percentage') {
                    let dCharges = totalAmount * (item.charge / 100);
                    chargeValue = parseFloat(dCharges);
                } else {
                    chargeValue = item.charge;
                }
            }
        });
        return chargeValue;
    };

    const getSumPayable = () => {
        let deliverycharge = 0;
        let totalValue;
        if (!flattenedItems.length) {
            return 0;
        }
        const allWithTaxes = flattenedItems.map((item) => getPrice(item));
        totalValue = allWithTaxes.reduce((a, b) => a + b);
        if (hotelDetails.hotel_delivery_types.length && deliveryType) {
            deliverycharge = getDeliveryCharges(totalValue);
        }
        return totalValue + deliverycharge;
    };

    const updateSelection = (quantity, id, index) => {
        forceRender();
        const itemFromCatalogue = flattenedItems.find((item) => item.id === id);
        // const alreadySelected = selectedItems.findIndex(
        //   (item) => item.id === itemFromCatalogue.id
        // );
        const alreadySelected = index;
        if (
            itemFromCatalogue.addons &&
            itemFromCatalogue.addons.length !== 0 &&
            quantity == -1 &&
            itemFromCatalogue?.quantity == 1
        ) {
            const s = selectedItems;
            s.splice(index, 1);
            selectItems(s?.filter((x) => x.quantity != 0));
            return;
        }

        if (alreadySelected == -1) {
            selectItems((contain) => [
                ...contain,
                {
                    ...itemFromCatalogue,
                    quantity: 1,
                },
            ]);
            return;
        }
        let mutableItems = selectedItems;
        const newQuantity = mutableItems[alreadySelected].quantity + quantity;

        mutableItems[alreadySelected].quantity = newQuantity;
        selectItems(() => mutableItems?.filter((x) => x.quantity != 0));
        return forceRender(Math.random());
    };

    const isAllowed = selectedItems.length;

    const changeDateFormat = async (date) => {
        var selecteddate = new Date(date);
        let day = '' + selecteddate.getDate();
        let month = '' + (selecteddate.getMonth() + 1);
        let year = selecteddate.getFullYear();
        if (month.length < 2) month = 0 + month;
        if (day.length < 2) day = 0 + day;
        return year + '/' + month + '/' + day;
    };
    const placeOrderHandler = async () => {
        if (!immediatePU && PUDate === '' && PUHour === '') {
            setError(t['checkout_laundry_error']);
            toggleImmediatePU(false);
            window.scrollTo(0, 0);

            return false;
        }
        if (!roomNo) {
            setError(t['checkout_laundry_error']);
            setLoading(false);
            window.scrollTo(0, 0);

            return;
        }
        if (hotelDetails?.hotel_delivery_types?.length && !deliveryType) {
            setError('Please select a Delivery Type');
            setLoading(false);
            window.scrollTo(0, 0);

            return;
        }
        await localStorage.setItem('room_no', roomNo);
        const getFormatedDate = await changeDateFormat(PUDate);
        var selectedtime = `${getFormatedDate} ${PUHour}`;
        var picupdate;

        if (immediatePU) {
            picupdate = convertTimeUTC(moment().format('YYYY/MM/DD HH:mm:ss'));
        } else {
            picupdate = convertTimeUTC(selectedtime);
        }
        setLoading(true);
        setError('');

        try {
            let laundryorderData = {
                pickup: picupdate,
                instructions,
                deliveryType: deliveryType == null ? '' : deliveryType,
                items: selectedItems
                    .filter((entry) => entry.quantity)
                    .map((entry) => ({
                        laundry_item_id: entry.id,
                        quantity: entry.quantity,
                        addons: Object.keys(entry.addons)
                            ?.filter((item) => entry.addons[item])
                            .map((finalItem) => {
                                return {
                                    laundry_item_addon_id: finalItem,
                                    quantity: 1,
                                };
                            }),
                    })),
            };
            await placeOrder(laundryorderData);
            setLoading(false);
            toggleImmediatePU(false);
            ReactGA.set({ laundryOrderPlaced: true });
            ReactGA.event({
                category: 'Laundry',
                action: 'Laundry Order Placed',
            });
            return navigate('/laundry-thank-you');
        } catch (e) {
            setLoading(false);
            setError(t['checkout_laundry_error']);
            console.log(e);
        }
    };

    const onRoonNoBlur = () => {
        setRoomverificationLoader(true);
        getVerifyRoomNo(roomNo)
            .then((res) => {
                const r = res?.data;
                setError('');
            })
            .catch((err) => {
                const e = err?.response?.status;
                if (e === 404) {
                    setRoomNo(null);
                    window.scrollTo(0, 0);
                    setError(t['valid_room_no_msg']);
                }
            })
            .finally(() => setRoomverificationLoader(false));
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <>
            <Header showBack />
            {hotelDetails?.hotel_term?.map((item) => {
                if (item?.type === 'ird_cart_top') {
                    return (
                        <p
                            className={'ird-checkout-warning '}
                            dangerouslySetInnerHTML={{
                                __html: `&#x26A0; ${item?.term}`,
                            }}
                        ></p>
                    );
                }
            })}
            <div className='flex flex-col gap-12 px-2 py-2 pb-48 relative bg-gray'>
                <div className='flex flex-col gap-2'>
                    {error && (
                        <div className={styles.errorMessage}>
                            <InfoCircleOutlined style={{ color: '#1c1c1c' }} />
                            <small>
                                {error || t['checkout_laundry_error']}
                            </small>
                        </div>
                    )}
                    <h3>{t['checkout_laundry_title']}</h3>
                    {selectedItems?.map((entry, i) => (
                        <CheckoutCard
                            {...entry}
                            key={Math.random()}
                            laundry={entry.taxes.length === 0}
                            inclTaxes={t['total_taxes']}
                            taxedPrice={getPrice(entry)}
                            defaultQuantity={selectedItems[i]}
                            increaseQuantity={() =>
                                updateSelection(+1, entry.id, i)
                            }
                            decreaseQuantity={() =>
                                updateSelection(-1, entry.id, i)
                            }
                        />
                    ))}
                    {/* <div className='priceDetails-wraper shadow-sm border-1'>
                        <div className={`details-list`}>
                            <span className='type_label'>{data?.label}</span>
                            <span className='value_label'>
                                {currentCurrency}{' '}
                                {renderPrice(data.value, hotelDetails)}
                            </span>
                        </div>
                    </div> */}
                    <div className='priceDetails-wraper shadow-sm border-1'>
                        {getTaxes().length > 0 &&
                            getTaxes()?.map((t) => {
                                return (
                                    <>
                                        <div className={`details-list`}>
                                            <span className='type_label'>
                                                {t?.name}
                                            </span>
                                            <span className='value_label'>
                                                {currentCurrency}{' '}
                                                {renderPrice(
                                                    t.tax,
                                                    hotelDetails
                                                )}
                                            </span>
                                        </div>
                                    </>
                                );
                            })}
                        <div className={`details-list grand_total`}>
                            <span className='type_label'>
                                {t['common_grand_total']}
                            </span>
                            <span className='value_label'>
                                {currentCurrency}{' '}
                                {renderPrice(getSumPayable(), hotelDetails)}
                            </span>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2'>
                    {hotelDetails?.hotel_delivery_types?.length ? (
                        <div className='deliveryType_wrapper gap-2'>
                            {hotelDetails.hotel_delivery_types.map((option) => (
                                <div className='option_type gap-2'>
                                    <input
                                        className={styles.timeInputCheckbox}
                                        type='radio'
                                        id={option.id}
                                        name='deliveryType'
                                        value={option.id}
                                        onChange={(e) =>
                                            setDeliveryType(option.id)
                                        }
                                    />
                                    <label htmlFor={option.id}>
                                        {option.name}
                                    </label>
                                </div>
                            ))}
                        </div>
                    ) : null}
                    <div
                        className={
                            'preOrder-wrapper flex gap-3 flex-col' +
                            (!immediatePU ? ' on' : '')
                        }
                    >
                        <div className='flex  gap-2'>
                            <Switch
                                value={immediatePU}
                                onChange={() => {
                                    setPUDate('');
                                    setPUHour('');
                                    toggleImmediatePU(
                                        (immediatePU) => !immediatePU
                                    );
                                }}
                            />
                            <label>{t['checkout_laundry_pickup']}</label>
                        </div>
                        {!immediatePU && (
                            <>
                                <div className={styles.timeInputWrapper}>
                                    <label>{t['common_select_date']} </label>
                                    <DatePickerComponent
                                        placeholder={t['select_date']}
                                        value={PUDate}
                                        config={dateConfig}
                                        confirmText={t['common_done_btn']}
                                        cancelText={t['common_cancel']}
                                        saveTime={(time) => setPUDate(time)}
                                        cType='date'
                                    />
                                </div>
                                <div className={styles.timeInputWrapper}>
                                    <label>{t['common_select_time']} </label>
                                    <DatePickerComponent
                                        placeholder={t['select_time']}
                                        value={PUHour}
                                        config={timeConfig}
                                        confirmText={t['common_done_btn']}
                                        cancelText={t['common_cancel']}
                                        errorText={`${t['checkout_common_time_error_msg']} ${minHour}:00 and ${maxHour}:00`}
                                        saveTime={(time) => setPUHour(time)}
                                        cType='hour'
                                        minHour={minHour}
                                        maxHour={maxHour}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className={'flex  justify-between items-center gap-1'}>
                        <label style={{ flex: '1 1 80%'}}>{t['room_villa_no']} </label>
                        <div style={{ flex: '1 1', textAlign: 'center' }}>
                            {roomverificationLoader ? (
                                <Loading
                                    type='ThreeDots'
                                    color='#665551'
                                    height={10}
                                    width={40}
                                />
                            ) : (
                                ''
                            )}
                            {!isRoomNoInUrl ? (
                                <input
                                    type='text'
                                    value={roomNo}
                                    onChange={(e) => {
                                        setRoomNo(e?.target?.value);
                                    }}
                                    onBlur={() => {
                                        onRoonNoBlur();
                                    }}
                                    placeholder={t['room_villa_no']}
                                    className='roomNumberInput flex-1'
                                />
                            ) : (
                                <p>{roomNumber}</p>
                            )}
                        </div>
                    </div>
                    <div
                        className={
                            'flex  justify-center items-start flex-col gap-2'
                        }
                    >
                        <label>{t['checkout_instructions']}</label>
                        <TextArea
                            value={instructions}
                            onChange={(e) => updateInstructions(e.target.value)}
                            rows={3}
                        />
                    </div>

                    {!getTnC() && (
                        <Checkbox
                            onChange={(e) => setIsChecked(e.target.checked)}
                            checked={isChecked}
                            id='term-check'
                        >
                            {t['common_agree_statement']}
                            <span
                                style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                }}
                                onClick={() => {
                                    setisDisclaimer(true);
                                }}
                            >
                                {t['laundry_terms_and_conditions']}
                            </span>
                        </Checkbox>
                    )}
                    <div className='flex justify-center items-center gap-2 mt-auto'>
                        <Button onClick={() => navigate(-1)}>
                            {t['back_btn']}
                        </Button>
                        <Button
                            type='primary'
                            disabled={
                                (PUDate == '' ||
                                    PUHour == '' ||
                                    roomNo === '') &&
                                !immediatePU &&
                                !isChecked
                            }
                            onClick={
                                isAllowed ? () => placeOrderHandler() : null
                            }
                            size='large'
                            className='bg-theme'

                        >
                            {t['common_proceed_btn']}
                        </Button>
                    </div>
                </div>
            </div>

            {isDisclaimer && (
                <Disclaimer
                    onClose={() => setisDisclaimer(false)}
                    disclaimerText={getTnC()}
                    open={isDisclaimer}
                />
            )}
        </>
    );
};

export default DataProvider(Checkout);
