import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Loading,
    Menu,
    Header,
    Disclaimer,
    Counter,
} from '../../components/components';
import {
    FOOD_IMAGE_ROOT_URL as IMAGE_ROOT_URL,
    IRDMENU_IMAGE_ROOT_URL,
    IRDITEM_IMAGE_ROOT_URL,
} from '../../services/api';
import styles from './orderFood.module.css';
import ReactGA from 'react-ga4';
import AddonComponent from './AddonComponent';

import {
    getLocalCartItems,
    removeLocalCartItem,
    updateCartItem,
    addLocalCartItem,
    generateCartItem,
    renderPrice,
} from '../../helper/commonHelper';
import AddonRepeatConfirm from './AddonRepeatConfirm';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMenuItems, irdSelector } from '../../store/slices/irdSlice';
import { printCurrencySymbol } from '../../helpers/commonHelper';
import { useHotel } from '../../context/HotelContext';
import { useLanguage } from '../../context/LanguageContext';
import { Switch, Button } from 'antd';
// let menuItems = [];
const OrderFood = (props) => {
    const {
        loading,
        menuItems: fetchedMenuItems,
        selectedMenu,
    } = useSelector(irdSelector);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useLanguage();
    let { menuID } = useParams();
    const { lang } = useLanguage();
    const { hotelDetails, translations } = useHotel();
    const currentCurrency = printCurrencySymbol(hotelDetails);

    const [menu, toggleMenu] = useState(false);
    const [isDisclaimer, setisDisclaimer] = useState(false);
    const [menuItems, setMenuItems] = useState([]);
    const [hasNonVegItem, setHasNonVegItem] = useState(false);
    const [hasHealthyItem, sethasHealthyItem] = useState(false);
    const [nonVegToggle, setNonVegToggle] = useState(false);
    const [healthyItemsToggle, setHealthyItemsToggle] = useState(false);

    //addon implementation
    const [selectedMenuItem, setselectedMenuItem] = useState(null);
    const [selectedCartItemID, setselectedCartItemID] = useState(null);
    const [isOpenAddonPopup, setIsOpenAddonPopup] = useState(false);
    const [localCart, setlocalCart] = useState([]);

    // addon confirmation item
    const [addonConfirmItem, setAddonConfirmItem] = useState(null);
    const [isOpenAddonConfirm, setIsOpenAddonConfirm] = useState(false);
    const [operationType, setOperationType] = useState({});

    // const sessionID = localStorage.initiate();
    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(fetchMenuItems(menuID));
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    useEffect(() => {
        let deepCopyMenuItems = JSON.parse(JSON.stringify(fetchedMenuItems));
        updateLocalCartItems();
        initMenuItems(deepCopyMenuItems);
        setMenuItems(deepCopyMenuItems);
    }, [fetchedMenuItems]);

    function initMenuItems(dataitems) {
        // Re-building menu-item which has cart in it
        for (const category of dataitems) {
            const menuSubCategoryItems = category.sub_categories;
            const menuWithoutSubCategoryItems =
                category.without_sub_category_items;
            for (const subCategory of menuSubCategoryItems) {
                const subCategoryItems = subCategory.items;

                // adding cart property in each  item of sub-categoryItems
                // menu item is populated with cart item field
                subCategory.items = populateCartFields(subCategoryItems);
            }
            // Without sub category items is populated with cart item field
            category.without_sub_category_items = populateCartFields(
                menuWithoutSubCategoryItems
            );
        }
    }

    function isItemNonVeg(item) {
        // If any one of the item is non veg then no need check whether item has non-veg category
        if (hasNonVegItem) {
            return;
        }

        if (item.type == 'non_veg') {
            setHasNonVegItem(true);
            return;
        }

        const isAddonItemNonVeg = (addons) => {
            if (hasNonVegItem) {
                return;
            }
            addons.forEach((addOn) => {
                if (hasNonVegItem) {
                    return;
                }
                if (addOn.type == 'non-veg') {
                    setHasNonVegItem(true);
                    return;
                }
            });
        };

        item.sub_addons?.forEach((subAddOn) => {
            isAddonItemNonVeg(subAddOn.addons);
        });
        isAddonItemNonVeg(item.without_subaddon_addons);
    }
    function isItemHealthy(item) {
        // If any one of the item is non veg then no need check whether item has non-veg category
        if (hasHealthyItem) {
            return;
        }

        if (item.is_healthy == 1) {
            sethasHealthyItem(true);
            return;
        }

        const isAddonItemNonVeg = (addons) => {
            if (hasHealthyItem) {
                return;
            }
            addons.forEach((addOn) => {
                if (hasHealthyItem) {
                    return;
                }
                if (addOn.is_healthy == 1) {
                    sethasHealthyItem(true);
                    return;
                }
            });
        };

        item.sub_addons?.forEach((subAddOn) => {
            isAddonItemNonVeg(subAddOn.addons);
        });
        isAddonItemNonVeg(item.without_subaddon_addons);
    }

    function hasvegItemsList(category) {
        let veg = true;

        const hasVegInList = (itemsList) => {
            for (let i = 0; i < itemsList.length; i++) {
                const element = itemsList[i];
                if (element.type !== 'non_veg' && element.enabled === 1) {
                    veg = false;
                    break;
                }
            }
        };

        category.sub_categories.forEach((subCategory) => {
            hasVegInList(subCategory.items);
        });
        hasVegInList(category.without_sub_category_items);

        return veg;
    }

    function populateCartFields(items) {
        const populatedItem = items?.map((item) => {
            // updating total selected count
            updateAddonCount(item);
            isItemNonVeg(item);
            isItemHealthy(item);

            // checking whether item has non-veg item
            // new cartItems property would be added in case cart item updated
            item['cartItems'] =
                localCart?.filter((cart) => cart?.id === item.id) || [];
            return item;
        });
        return populatedItem;
    }
    // update mylocal cartitems
    function updateLocalCartItems() {
        let localCartData = getLocalCartItems();
        setlocalCart(localCartData);
    }
    // add count field to addon
    function updateAddonCount(item) {
        // Add addon and subAddon count = 0 for all available addon inside menuItem
        const updateCount = (addOns) => {
            for (const addOn of addOns) {
                addOn.count = 0;
            }
        };
        // adding sub-addon category and without sub-addon items in a single place
        item.sub_addons?.forEach((subAdd) => {
            updateCount(subAdd.addons);
        });
        updateCount(item.without_subaddon_addons);
    }
    const getcategories = () => {
        const categories = menuItems
            ?.filter((item) => item.enabled === 1)
            ?.map((category) => ({
                name: category.name,
                id: category.id,
            }));
        return categories;
    };

    function getOperationType(type) {
        setOperationType(type);
    }
    const handleAddonModal = (menuItem, cartItemId = null) => {
        setselectedMenuItem(menuItem);
        setselectedCartItemID(cartItemId);
    };

    // show confirmation dialog before removing and adding if menu_item has multiple selected items
    const handleConfirmationModal = (menuItem) => {
        // specify index if we are modifying the existing cart
        setAddonConfirmItem(menuItem);
        updateLocalCartItems();
    };

    function getAddonOperationType(data) {
        setAddonConfirmItem(null);
        // update feature is disabled  (uncomment line 60 in restaurant-addon-confirmation.html to enable this feature)
        if (data && data.operation === 'update') {
            // If cart has been updated then updating the cart instance
            // handleAddonModal(data.cartItemId);
        } else if (data && data.operation === 'add') {
            // If cart has been updated then updating the cart instance
            setIsOpenAddonPopup(true);
            handleAddonModal(data.menuItem);
        } else {
            // If cart has been deleted or duplicated the updating the cart instance
            updateLocalCartItems();
        }
    }
    // Handling Addons modal if items has addons
    async function handleAddonsModal(menuItem, operationType) {
        if (menuItem.cartItems?.length > 0) {
            // Here operation will be managed dynamically in the confirmation dialog based on user feedback
            setIsOpenAddonConfirm(true);
            await handleConfirmationModal(menuItem);
        } else if (operationType === 'increment') {
            //    If no selected items then directly show the addon modal
            setIsOpenAddonPopup(true);
            await handleAddonModal(menuItem);
        }
    }

    async function itemOperationOnLocal(menuItem, operationType) {
        // remove item (remove last item, if item has 2 count) from cart
        const decrementItem = () => {
            // remove cart item if item count is 1
            if (menuItem.cartItems[0].cart_item_count === 1) {
                const removedItem = menuItem.cartItems.pop();
                removeLocalCartItem(removedItem.cartItemId);
                updateLocalCartItems();
            } else {
                // IDecrement cart_item_count if item doesn't have sub-addon //
                menuItem.cartItems[0].cart_item_count += -1;
                updateCartItem(menuItem.cartItems[0]);
            }
            updateLocalCartItems();
        };

        if (
            menuItem.sub_addons?.map((x) => x.enabled).indexOf(1) > -1 ||
            menuItem.without_subaddon_addons.length > 0
        ) {
            if (
                operationType !== 'increment' &&
                menuItem.cartItems?.length === 1
            ) {
                // If selected item has only one category of selected cart item and contains sub-addons to remove
                return decrementItem();
            }

            // If it has sub-addons
            await handleAddonsModal(menuItem, operationType);
        } else {
            // If item doesnt has sub-addons
            if (operationType === 'increment') {
                if (menuItem?.cartItems?.length === 0) {
                    // Add item if item doesn't have sub-addon //
                    const cartItem = generateCartItem(menuItem);
                    menuItem.cartItems.push(cartItem);
                    addLocalCartItem(cartItem);
                } else {
                    // Increment cart_item_count if item doesn't have sub-addon //
                    menuItem.cartItems[0].cart_item_count += 1;
                    updateCartItem(menuItem.cartItems[0]);
                }
                updateLocalCartItems();
            } else {
                // Remove item if item doesnt have sub-addon
                decrementItem();
            }
        }
    }

    // Adding item to cart using local storage (Current Approach)
    async function addItemToLocal(menuItem) {
        await itemOperationOnLocal(menuItem, 'increment');
    }

    // returns total count for each item
    function getCartItemCount(menuItem) {
        let cartCount = 0;
        if (menuItem.cartItems?.length > 0) {
            menuItem.cartItems.forEach(
                (item) => (cartCount += item.cart_item_count)
            );
        }
        return cartCount;
    }

    // Decrement item qty to cart using local storage (Current Approach)
    async function decrementQtyToLocal(menuItem) {
        if (menuItem.cartItems?.length < 1) {
            return;
        }
        await itemOperationOnLocal(menuItem, 'decrement');
    }

    const cartLength = () => {
        let localCartData = getLocalCartItems();
        return localCartData.length;
    };

    const cartLengthAddons = () => {
        let localCartData = getLocalCartItems();
        const sumCounter = localCartData
            ?.map((i) => i.cart_item_count)
            .reduce((a, b) => a + b);
        return sumCounter;
    };

    const menuCategogries = getcategories();

    const openDisclaimer = () => {
        setisDisclaimer(!isDisclaimer);
    };

    function handleAddonClose() {
        setIsOpenAddonPopup(false);
        // dispatch(fetchMenuItems(menuID));

        updateLocalCartItems();
        syncItemsAfterAddonUpdate();
    }
    function handleAddonConfirmClose() {
        setIsOpenAddonConfirm(false);
        // dispatch(fetchMenuItems(menuID));
        updateLocalCartItems();
        syncItemsAfterAddonUpdate();
    }
    function syncItemsAfterAddonUpdate() {
        let localCartData = getLocalCartItems();
        const updatePopulateCartFields = (items) => {
            const populatedItem = items?.map((item) => {
                // updating total selected count
                updateAddonCount(item);
                // checking whether item has non-veg item
                // new cartItems property would be added in case cart item updated
                item['cartItems'] =
                    localCartData?.filter((cart) => cart?.id === item.id) || [];
                return item;
            });
            return populatedItem;
        };
        const clonedItems = structuredClone(menuItems);
        for (const category of clonedItems) {
            const menuSubCategoryItems = category.sub_categories;
            const menuWithoutSubCategoryItems =
                category.without_sub_category_items;
            for (const subCategory of menuSubCategoryItems) {
                const subCategoryItems = subCategory.items;

                // adding cart property in each  item of sub-categoryItems
                // menu item is populated with cart item field
                subCategory.items = updatePopulateCartFields(subCategoryItems);
            }
            // Without sub category items is populated with cart item field
            category.without_sub_category_items = updatePopulateCartFields(
                menuWithoutSubCategoryItems
            );
        }

        setMenuItems(clonedItems);
    }

    const tagColor =
        hotelDetails.id === 12 ||
        hotelDetails.id === 16 ||
        hotelDetails.id === 204 ||
        hotelDetails.id === 206 ||
        hotelDetails.id === 207 ||
        hotelDetails.id === 208 ||
        hotelDetails.id === 209 ||
        hotelDetails.id === 210 ||
        hotelDetails.id === 332 ||
        hotelDetails.id === 389
            ? ' tag-aqua'
            : hotelDetails.id === 386
            ? ' tag-bg-bronze'
            : '';
    const renderFoods = (subcategories) => {
        return React.Children.toArray(
            subcategories?.map((subCat) => {
                return !subCat.enabled ? (
                    <div />
                ) : (
                    <div className={'flex flex-col gap-4'} key={subCat.id}>
                        <div className={styles.saladsBoxTitle}>
                            <div className='categoryContainer'>
                                <p>{subCat.name}</p>
                            </div>
                            {subCat.description && (
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: subCat.description,
                                    }}
                                ></p>
                            )}
                        </div>
                        <div className={'flex flex-col gap-4'}>
                            {subCat.items?.sort((a,b)=> b.priority - a.priority)?.map(
                                (menuItem) =>
                                    menuItem.enabled === 1 &&
                                    !(
                                        nonVegToggle &&
                                        menuItem.type == 'non_veg'
                                    ) &&
                                    !(
                                        healthyItemsToggle &&
                                        menuItem.is_healthy === 0
                                    ) && (
                                        <div
                                            className='item-food-card'
                                            key={menuItem.id}
                                        >
                                            <div
                                                className={`item-details-wrapper border border-gray-300 ${menuItem.display_type}`}
                                            >
                                                <div className='item-thumbnail'>
                                                    {menuItem.thumbnail && (
                                                        <img
                                                            src={
                                                                IRDITEM_IMAGE_ROOT_URL +
                                                                menuItem.thumbnail
                                                            }
                                                            alt={menuItem.name}
                                                            className='item-image'
                                                        />
                                                    )}
                                                    {(menuItem.type === 'veg' ||
                                                        menuItem.type ===
                                                            'non_veg') && (
                                                        <div
                                                            className={
                                                                'icon-img ' +
                                                                (menuItem.type ===
                                                                'non_veg'
                                                                    ? 'non-veg'
                                                                    : 'veg')
                                                            }
                                                        ></div>
                                                    )}
                                                    {/* {menuItem.type === "veg" && (
                            <img
                              src={veg}
                              alt="veg_icon"
                              className="item-type"
                            />
                          )}
                          {menuItem.type === "non_veg" && (
                            <img
                              src={nonveg}
                              alt="nonveg_icon"
                              className="item-type"
                            />
                          )} */}
                                                </div>

                                                <div className='item-title-wrapper'>
                                                    <div className='item-title-price-wrapper'>
                                                        <div className='item-title'>
                                                            {menuItem.name}
                                                        </div>
                                                        {selectedMenu?.view_only ===
                                                        0 ? (
                                                            <div className='item-price'>
                                                                {menuItem.price >
                                                                0
                                                                    ? `${currentCurrency} ${renderPrice(
                                                                          menuItem.price,
                                                                          hotelDetails
                                                                      )}`
                                                                    : `  `}
                                                            </div>
                                                        ) : null}
                                                        {JSON.parse(
                                                            menuItem?.tags
                                                        )?.length ? (
                                                            <ul className='tagcontainer'>
                                                                {React.Children.toArray(
                                                                    JSON.parse(
                                                                        menuItem.tags
                                                                    )?.map(
                                                                        (
                                                                            tag
                                                                        ) => (
                                                                            <li
                                                                                className={`tag ${tagColor}`}
                                                                            >
                                                                                {
                                                                                    tag
                                                                                }
                                                                            </li>
                                                                        )
                                                                    )
                                                                )}
                                                            </ul>
                                                        ) : null}
                                                        <div
                                                            className='item-description'
                                                            dangerouslySetInnerHTML={{
                                                                __html: menuItem.description,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    {selectedMenu?.view_only ===
                                                    0 ? (
                                                        <div className='item-actions'>
                                                            {menuItem?.cartItems
                                                                ?.length ? (
                                                                <Counter
                                                                    count={getCartItemCount(
                                                                        menuItem
                                                                    )}
                                                                    increment={() =>
                                                                        addItemToLocal(
                                                                            menuItem
                                                                        )
                                                                    }
                                                                    decrement={() =>
                                                                        decrementQtyToLocal(
                                                                            menuItem
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                <Button
                                                                    onClick={() =>
                                                                        addItemToLocal(
                                                                            menuItem
                                                                        )
                                                                    }
                                                                    label={t['Add']}
                                                                    className='add-btn-food'
                                                                >
                                                                    {t['Add']}
                                                                </Button>
                                                            )}
                                                            {menuItem.sub_addons
                                                                .length &&
                                                            menuItem?.cartItems
                                                                ?.length ===
                                                                0 ? (
                                                                <p className='customizable'>
                                                                    {
                                                                        t[
                                                                            'ird_customizable'
                                                                        ]
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    ) : (
                                                        <div className='item-price'>
                                                            {menuItem.price > 0
                                                                ? `${currentCurrency} ${renderPrice(
                                                                      menuItem.price,
                                                                      hotelDetails
                                                                  )}`
                                                                : `  `}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        // </div>
                                    )
                            )}
                        </div>
                    </div>
                );
            })
        );
    };
    const renderFoodsubcategory = (subcategories) => {
        return React.Children.toArray(
            subcategories?.sort((a,b)=> b.priority - a.priority)?.map((menuItem) => {
                return (
                    menuItem.enabled === 1 &&
                    !(nonVegToggle && menuItem.type == 'non_veg') &&
                    !(healthyItemsToggle && menuItem.is_healthy === 0) && (
                        <div className='item-food-card' key={menuItem.id}>
                            <div
                                className={`item-details-wrapper border border-gray-300 ${menuItem.display_type}`}
                            >
                                <div className='item-thumbnail'>
                                    {menuItem.thumbnail && (
                                        <img
                                            src={
                                                IRDITEM_IMAGE_ROOT_URL +
                                                menuItem.thumbnail
                                            }
                                            alt={menuItem.name}
                                            className='item-image'
                                        />
                                    )}
                                    {(menuItem.type === 'veg' ||
                                        menuItem.type === 'non_veg') && (
                                        <div
                                            className={
                                                'icon-img ' +
                                                (menuItem.type === 'non_veg'
                                                    ? 'non-veg'
                                                    : 'veg')
                                            }
                                        ></div>
                                    )}
                                    {/* {menuItem.type === 'veg' && (
                    <img src={veg} alt='veg_icon' className='item-type' />
                  )}
                  {menuItem.type === 'non_veg' && (
                    <img src={nonveg} alt='nonveg_icon' className='item-type' />
                  )} */}
                                </div>

                                <div className='item-title-wrapper'>
                                    <div className='item-title-price-wrapper'>
                                        <div className='item-title'>
                                            {menuItem.name}
                                        </div>
                                        {selectedMenu?.view_only === 0 ? (
                                            <div className='item-price'>
                                                {menuItem.price > 0
                                                    ? `${currentCurrency} ${renderPrice(
                                                          menuItem.price,
                                                          hotelDetails
                                                      )}`
                                                    : `  `}
                                            </div>
                                        ) : null}
                                        {JSON.parse(menuItem?.tags)?.length ? (
                                            <ul className='tagcontainer'>
                                                {React.Children.toArray(
                                                    JSON.parse(
                                                        menuItem.tags
                                                    )?.map((tag) => (
                                                        <li
                                                            className={`tag ${tagColor}`}
                                                        >
                                                            {tag}
                                                        </li>
                                                    ))
                                                )}
                                            </ul>
                                        ) : null}
                                        <div
                                            className='item-description'
                                            dangerouslySetInnerHTML={{
                                                __html: menuItem.description,
                                            }}
                                        ></div>
                                    </div>

                                    {selectedMenu?.view_only === 0 ? (
                                        <div className='item-actions'>
                                            {menuItem?.cartItems?.length ===
                                                0 && (
                                                <Button
                                                    onClick={() =>
                                                        addItemToLocal(menuItem)
                                                    }
                                                                    label={t['Add']}
                                                                    className='add-btn-food'
                                                >
                                                    {t['Add']}
                                                </Button>
                                            )}
                                            {menuItem.sub_addons.length &&
                                            menuItem?.cartItems?.length ===
                                                0 ? (
                                                <p className='customizable'>
                                                    {t['ird_customizable']}
                                                </p>
                                            ) : null}
                                            {menuItem?.cartItems?.length !==
                                                0 && (
                                                <Counter
                                                    count={getCartItemCount(
                                                        menuItem
                                                    )}
                                                    decrement={() =>
                                                        decrementQtyToLocal(
                                                            menuItem
                                                        )
                                                    }
                                                    increment={() =>
                                                        addItemToLocal(menuItem)
                                                    }
                                                />
                                            )}
                                        </div>
                                    ) : (
                                        <div className='item-price'>
                                            {menuItem.price > 0
                                                ? `${currentCurrency} ${renderPrice(
                                                      menuItem.price,
                                                      hotelDetails
                                                  )}`
                                                : `  `}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                );
            })
        );
    };
    return loading ? (
        <Loading />
    ) : menuItems.length === 0 ? (
        t['no_item_found']
    ) : (
        <>
            <Header
                showBack
                popupData={selectedMenu.description}
                openDisclaimer={openDisclaimer}
                disclaimerText={selectedMenu.description}
            />
            <div
                className={
                    'fixedLayout ' + (hotelDetails.id === 343 ? 'bg-image' : '')
                }
            >
                {menuCategogries.length > 2 && (
                    <>
                        {menu && (
                            <>
                                <div
                                    className={styles.overlay}
                                    onClick={() => toggleMenu(false)}
                                    id='Adesh1'
                                ></div>
                                <div
                                    className={styles.menuWrapper}
                                    style={{
                                        height:
                                            menuCategogries.length > 7
                                                ? '35vh'
                                                : `${menuCategogries.length}` *
                                                      32 +
                                                  4 +
                                                  'px',
                                    }}
                                >
                                    <Menu
                                        subcategories={menuCategogries}
                                        onClose={() => toggleMenu(false)}
                                    />
                                </div>
                            </>
                        )}
                        <Button
                            type='primary'
                            className='menu-fixed-btn bg-theme'
                            onClick={() => toggleMenu(!menu)}
                        >
                            {t['ird_menu']}
                        </Button>
                    </>
                )}

                {(hasNonVegItem || hasHealthyItem) && (
                    <>
                        <div
                            className='veg-healthy-wrapper'
                            style={{
                                display: 'flex',
                                gap: '1rem',
                            }}
                        >
                            {hasNonVegItem ? (
                                <div className='filter-veg flex gap-2'>
                                    <label>
                                        {hotelDetails.id === 517
                                            ? `${t['ird_vegetarian_only']} / 纯素食`
                                            : t['ird_vegetarian_only']}
                                    </label>
                                    <Switch
                                        checked={nonVegToggle}
                                        onChange={() =>
                                            setNonVegToggle(
                                                (nonVegToggle) => !nonVegToggle
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                            {hasHealthyItem ? (
                                <div className='filter-veg flex gap-2'>
                                    <p>{t['healthy_items']}</p>
                                    <Switch
                                        value={healthyItemsToggle}
                                        onChange={() =>
                                            setHealthyItemsToggle(
                                                (healthyItemsToggle) =>
                                                    !healthyItemsToggle
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </>
                )}

                {selectedMenu.banner ? (
                    <>
                        <img
                            className='cat-banner-image rounded-2xl'
                            src={IRDMENU_IMAGE_ROOT_URL + selectedMenu.banner}
                            alt='Banner'
                        />
                        <div className='green-divider'></div>
                    </>
                ) : (
                    ''
                )}
                <div className='flex flex-col gap-4'>
                    {React.Children.toArray(
                        menuItems?.map((category) => {
                            return (
                                category.enabled === 1 && (
                                    <>
                                        <div
                                            className={
                                                'category-details-wrapper ' +
                                                (nonVegToggle &&
                                                hasvegItemsList(category)
                                                    ? ' hide'
                                                    : ' ')
                                            }
                                        >
                                            {category.image && (
                                                <img
                                                    className='cat-banner-image rounded-2xl'
                                                    src={
                                                        IMAGE_ROOT_URL +
                                                        category.image
                                                    }
                                                    alt='Menu Banner'
                                                />
                                            )}
                                            <div
                                                className={styles.menuMainTitle}
                                            >
                                                <div
                                                    id={category.id}
                                                    className='category-name'
                                                >
                                                    {category.name}
                                                </div>
                                                {category.description && (
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: category.description,
                                                        }}
                                                    ></p>
                                                )}
                                            </div>
                                        </div>

                                        {renderFoods(category.sub_categories)}
                                        {renderFoodsubcategory(
                                            category.without_sub_category_items
                                        )}
                                    </>
                                )
                            );
                        })
                    )}
                </div>
            </div>
            {cartLength() ? (
                <div className={styles.cartCounterWrapper}>
                    <button
                        className='btn btn-checkout'
                        onClick={() => navigate('/checkout-food')}
                    >
                        <span>
                            {cartLengthAddons()} {t['items']}
                        </span>
                        <span>{t['ird_view_cart']}</span>
                    </button>
                </div>
            ) : (
                ''
            )}
            {isDisclaimer && (
                <Disclaimer
                    onClose={() => openDisclaimer()}
                    disclaimerText={selectedMenu.description}
                />
            )}
            {isOpenAddonPopup && selectedMenuItem && (
                <AddonComponent
                    selectedCartItemID={selectedCartItemID}
                    menuItem={selectedMenuItem}
                    open={isOpenAddonPopup}
                    close={handleAddonClose}
                    updateOperationType={getOperationType}
                />
            )}
            {isOpenAddonConfirm && addonConfirmItem && (
                <AddonRepeatConfirm
                    selectedItemList={addonConfirmItem}
                    menuItem={addonConfirmItem}
                    open={isOpenAddonConfirm}
                    close={handleAddonConfirmClose}
                    updateOperationType={getAddonOperationType}
                />
            )}
        </>
    );
};

export default OrderFood;
