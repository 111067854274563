import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Loading, Header } from '../../components/components';
import { IRDMENU_IMAGE_ROOT_URL } from '../../services/api';
import ReactGA from 'react-ga4';
import {
    updateMenuItems,
    updateSelectedMenu,
} from '../../store/slices/irdSlice';
import { useDispatch } from 'react-redux';
import { useHotel } from '../../context/HotelContext';
import axios from 'axios';
import { useLanguage } from '../../context/LanguageContext';

const NestedMenus = () => {
    const navigate = useNavigate();
    const [menuList, setMenuList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    let [searchParams] = useSearchParams();
    const submenuID = searchParams.get('submenuID');
    const { lang } = useLanguage()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: window.location.pathname + window.location.search,
            language: lang,
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
        if (submenuID) {
            fetchMenus(submenuID);
        }
    }, [submenuID]);

    const fetchMenus = async (submenuID) => {
        try {
            setLoading(true);
            const hotelID = localStorage.getItem('hotel_id') || false;
            const langCode = localStorage.getItem('lang') || 'en';
            const response = await axios.get(
                `/ird/guest/menu/categorise/${hotelID}/${submenuID}?lang_code=${langCode}`
            );
            if (response.status === 200) {
                const result = response.data.data || {};
                let allMenus = result.child_menus || [];
                allMenus =
                    allMenus
                        .filter((item) => Boolean(item.enabled))
                        .sort((a, b) => b.priority - a.priority) || [];
                setMenuList(allMenus);
            }
        } catch (error) {
            setError('Something went wrong! Please try again');
        } finally {
            setLoading(false);
        }
    };
    const getNestedMenu = async (item) => {
        try {
            setLoading(true);
            dispatch(updateSelectedMenu(item));
            const hotelID = localStorage.getItem('hotel_id') || false;
            const langCode = localStorage.getItem('lang') || 'en';
            const response = await axios.get(
                `/ird/guest/menu/categorise/${hotelID}/${item?.id}?lang_code=${langCode}`
            );
            if (response.status === 200) {
                const result = response.data.data || {};
                const childMenus = result.child_menus || [];
                const menuCategories = result?.menu_categories || [];
                if (childMenus.length > 0) {
                    navigate(`/submenus?submenuID=${item?.id}`);
                } else if (menuCategories.length > 0) {
                    dispatch(updateMenuItems([]));
                    navigate(`/order/${item?.id}`);
                }
            }
        } catch (error) {
        } finally {
            setLoading(() => false);
        }
    };

    const tileClickHandler = (item) => {
        if (item.type === 'default') {
            getNestedMenu(item);
        } else {
            navigate({
                pathname: '/content',
                data: item?.id,
            });
        }
    };

    return (
        <>
            <Header showBack />
            <div className={'homepage-container '}>
                <div className='allservices-container'>
                    {loading ? (
                        <Loading />
                    ) : error ? (
                        <p>{error}</p>
                    ) : menuList?.length === 0 ? (
                        <p>No Menus Found.</p>
                    ) : (
                        menuList
                            .filter((item) => Boolean(item.enabled))
                            .map((item) => (
                                <RenderMenuCard
                                    item={item}
                                    tileClickHandler={() =>
                                        tileClickHandler(item)
                                    }
                                />
                            ))
                    )}
                </div>
            </div>
        </>
    );
};

export default NestedMenus;

const RenderMenuCard = (props) => {
    const { item = {}, tileClickHandler } = props;
    const { hotelDetails } = useHotel();

    return (
        <div
            key={item.id}
            className={
                `service ${item.size_type} ${item.type} ` +
                (hotelDetails?.guest_primary_color ? 'apply-theme' : '')
            }
            style={{
                backgroundImage: `url(${IRDMENU_IMAGE_ROOT_URL}${encodeURIComponent(
                    item.image
                )})`,
            }}
            onClick={tileClickHandler}
        >
            <div className='service_title'>{item.name}</div>
        </div>
    );
};

// const getcategory = async (item) => {
//     const hotelID = localStorage.getItem('hotel_id') || false;
//     const langCode = localStorage.getItem('lang') || 'en';
//     dispatch(updateSelectedMenu(item));
//     setLoading(() => true);
//     const res = await getPrivateResource(
//         `/ird/guest/menu/categorise/${hotelID}/${item?.id}?lang_code=${langCode}`,
//         true
//     );
//     setLoading(() => false);
//     const data = res?.data?.data;
//     setContentMenu(data?.static_contents || []);
//     if (data?.child_menus?.length) {
//         const sorted =
//             data?.child_menus?.sort((a, b) => b.priority - a.priority) ||
//             [];
//         setMenuList(sorted);
//     } else if (data?.menu_categories?.length) {
//         dispatch(updateMenuItems([]));
//         navigate({
//             pathname: `/order/${item?.id}`,
//         });
//     }
// };
